body {
	font-family: $font;
	font-size: 14px;
	color: $defColor;
	background-color: $bgColor;
	font-weight: normal;
	line-height: normal;
	padding-top: 64px;

	@include media-breakpoint-down(lg) {
		padding-top: 60px;
	}

	&.open {
		overflow: hidden;
		position: relative;
		height: 100%;
	}
}

.container {
	@include media-breakpoint-up(xl) {
		max-width: 1230px;
	}
}

h1 {
	
}

img {
	vertical-align: top;
	max-width: 100%;
}

a {
}

a:hover {
}

section {
	float: left;
	width: 100%;
}
