.modal {
	.modal-dialog {
		max-width: 995px;
	}

	.modal-content {
		border: none;
		border-radius: 4px;
	}
}

.close-modal {
	border: none;
	position: absolute;
	width: 30px;
	height: 30px;
	top: 50px;
	right: 70px;
	background-color: transparent;

	@include media-breakpoint-down(sm) {
		top: 24px;
		right: 30px;
	}

	@include close-ico (20,1,#23293D);
}

.modal-wrap {
	display: inline-block;
	padding: 55px 70px;
	width: 100%;

	@include media-breakpoint-down(sm) {
		padding: 30px;
	}
}

.modal-tit {
	font-size: 24px;
	font-weight: 800;
	float: left;
	width: 100%;
	margin-bottom: 25px;

	@include media-breakpoint-down(sm) {
		font-size: 20px;
	}
}

.modal-form {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	float: left;
	width: 100%;
}

.one-modal-inp {
	width: calc((100% - 60px) / 3);
	position: relative;
	margin-bottom: 20px;

	@include media-breakpoint-down(sm) {
		width: 100%;
	}

	&:nth-child(1) {
		input {
			padding-left: 52px;
		}
	}

	&:nth-child(2) {
		input {
			padding-left: 88px;
		}
	}

	&:nth-child(3) {
		input {
			padding-left: 70px;
		}
	}

	label {
		padding: 0;
		margin: 0;
		font-weight: normal;
		float: left;
		width: 100%;
		span {
			color: #686A7E;
			font-size: 17px;
			font-weight: bold;
			position: absolute;
			line-height: 56px;
			left: 0;
		}

		input {
			width: 100%;
			height: 56px;
			border: none;
			border-bottom: 1px solid #CACACA;
		}
	}
}

.one-modal-area {
	float: left;
	width: 100%;
	margin-bottom: 50px;
	position: relative;

	@include media-breakpoint-down(sm) {
		margin-bottom: 30px;
	}

	label {
		padding: 0;
		margin: 0;
		font-weight: normal;
		float: left;
		width: 100%;

		span {
			color: #686A7E;
			font-size: 17px;
			font-weight: bold;
			position: absolute;
			line-height: 56px;
			left: 0;
			transition: all 0.3s;
		}

		textarea {
			width: 100%;
			height: 56px;
			border: none;
			padding: 19px 15px 0px 158px;
			border-bottom: 1px solid #CACACA;
			transition: all 0.3s;

			&:focus {
				padding-left: 15px;
			}

			&:focus + span {
				line-height: 1;
				top: -10px;
			}
		}
	}
}

.modal-bt {
	float: left;
	width: 564px;
	@include media-breakpoint-down(sm) {
		width: 100%;
	}
	button {
		width: 263px;
		float: left;

		@include media-breakpoint-down(sm) {
			width: 100%;
		}
	}

	.modal-bt-captcha {
		float: right;

		@include media-breakpoint-down(sm) {
			float: left;
			width: 100%;
			margin-bottom: 25px;
		}
	}
}