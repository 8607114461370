header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background-color: #fff;
	z-index: 99;
	height: 64px;

	.header {
		padding: 15px 0;
		float: left;
		width: 100%;
		position: relative;
	}

	.logo {
		float: left;
		a {
			color: $logoCol;
			line-height: 1;
			font-size: 15px;
		}

		img {
			float: left;
		}

		span {
			padding-left: 135px;
			display: block;

			@include media-breakpoint-down(sm) {
				display: none;
			}
		}
	}

	.header-mob-wrap {
		padding: 0 0 0 360px;

		@include media-breakpoint-down(lg) {
			display: none;
			position: absolute;
			top: 100%;
			right: 0;
			width: 300px;
			background-color: #fff;
			padding: 0;
			-webkit-box-shadow: 0px 3px 5px 0px rgba(50, 50, 50, 0.3);
			-moz-box-shadow:    0px 3px 5px 0px rgba(50, 50, 50, 0.3);
			box-shadow:         0px 3px 5px 0px rgba(50, 50, 50, 0.3);
		}

		@include media-breakpoint-down(sm) {
			position: fixed;
			width: auto;
			left: 0px;
			right: 0px;
			top: 0;
		}
	}

	.header-right {
		float: right;
		padding: 7px 0;

		@include media-breakpoint-down(lg) {
			background-color: #555EA6;
			padding: 20px 30px;
			width: 100%;
		}

		@include media-breakpoint-down(sm) {
			display: flex;
			justify-content: space-between;
		}
	}

	.mail {
		float: left;
		margin-right: 25px;
		margin-bottom: 15px;
		@include media-breakpoint-down(sm) {
			margin-bottom: 0;
		}
		a {
			text-decoration: none;
			color: $navColor;
			position: relative;
			padding-left: 28px;

			@include media-breakpoint-down(lg) {
				color: #fff;
			}

			& i {
				content: '';
				position: absolute;
				width: 18px;
				height: 14px;
				left: 0;
				top: 50%;
				margin-top: -8px;

				@include media-breakpoint-down(lg) {
					margin-top: -9px;
					svg {
						path {
							stroke: #fff;
						}

						rect {
							stroke: #fff;
						}
					}
				}
			}
		}
	}

	.phone {
		float: left;

		@include media-breakpoint-down(sm) {
			margin-bottom: 0;
		}
		a {
			text-decoration: none;
			color: $navColor;
			position: relative;
			padding-left: 28px;

			@include media-breakpoint-down(lg) {
				color: #fff;
			}

			& i {
				content: '';
				position: absolute;
				width: 18px;
				height: 18px;
				left: 0;
				top: 50%;
				margin-top: -9px;

				@include media-breakpoint-down(lg) {
					margin-top: -11px;
					svg {
						path {
							stroke: #fff;
						}

						rect {
							stroke: #fff;
						}
					}
				}
			}
		}
	}

	nav {
		padding: 0 360px 0 0;
		text-align: center;

		@include media-breakpoint-down(lg) {
			padding: 10px 0;
			width: 100%;
			float: left;
		}

		ul {
			display: inline-block;

			@include media-breakpoint-down(lg) {
				width: 100%;
			}
		}

		li {
			float: left;
			margin: 0 10px;

			@include media-breakpoint-down(lg) {
				width: 100%;
				text-align: left;
				margin: 0;
				padding: 0 30px;
			}
			a {
				display: block;
				cursor: pointer;
				color: $navColor;
				font-size: 16px;
				font-weight: 500;
				line-height: 30px;
				transition: all 0.3s;

				@include media-breakpoint-down(lg) {
					text-decoration: underline;
				}
			}
		}
	}
}

.show-menu {
	display: none;

	@include media-breakpoint-down(lg) {
		display: block;
		float: right;
		padding-right: 40px;
		width: 80px;
		position: relative;
		cursor: pointer;
		height: 30px;
	}

	&.open {
		.menu-line {
			span {
				opacity: 0;
			}

			&:before, &:after {
				opacity: 1;
				transform: rotate(-45deg);
			}

			&:after {
				transform: rotate(45deg);
			}
		}
	}
}

.menu-name {
	font-size: 15px;
	font-weight: 500;
	color: #000B6A;
	padding-top: 8px;
}

.menu-line {
	position: absolute;
	width: 25px;
	right: 0;
	top: 50%;
	margin-top: -12px;
	span {
		display: block;
		height: 1px;
		width: 100%;
		background-color: #000B6A;
		margin: 3px 0 4px;
		float: left;
		transition: all 0.3s;
	}

	&:before, &:after {
		content: '';
		position: absolute;
		left: 50%;
		margin-left: -12px;
		opacity: 0;
		width: 25px;
		margin-top: -1px;
		height: 1px;
		background-color: #000B6A;
		top: 50%;
		transition: all 0.3s;
	}

	
}

.header-top-tit {
	display: none;

	@include media-breakpoint-down(sm) {
		display: block;
		background-color: #000B6A;
		float: left;
		width: 100%;
		font-size: 15px;
		line-height: 64px;
		position: relative;
		color: #fff;
		padding: 0 30px;

		.close-menu {
			position: absolute;
			top: 0;
			right: 0;
			width: 64px;
			height: 64px;

			&:before, &:after {
				content: '';
				position: absolute;
				left: 50%;
				margin-left: -10px;
				width: 20px;
				margin-top: -1px;
				height: 1px;
				background-color: #fff;
				top: 50%;
				transform: rotate(45deg);
				transition: all 0.3s;
			}

			&:after {
				transform: rotate(-45deg);
			}
		}
	}
}

.bg-plan {
	@include media-breakpoint-down(sm) {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		background-color: rgba(0,0,0,0.5);
		display: none;
		height: 100%;
		z-index: 9;
	}
}