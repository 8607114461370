// placeholder 
@mixin placeholder ($color: #667780) {
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $color;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: $color;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: $color;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: $color;
  }
}

// css arrow
@mixin close-ico ($width,$height,$bgCol) {
  & {
    cursor: pointer;
  }

  &:before, &:after {
    content: '';
    width: #{$width}px;
    height: #{$height}px;
    border-radius: #{$height}px;
    background-color: $bgCol;
    position: absolute;
    top: 50%;
    margin-top: #{(-$height/2)}px;
    left: 50%;
    margin-left: #{(-$width/2)}px;
    transform: rotate(-45deg);
    transition: all 0.3s;
  }

  &:after {
    transform: rotate(45deg);
  }

  &:hover:before, &:hover:after {
    background-color: darken($bgCol, 50%);
  }
}

// defaul button 
@mixin def-light-bt ($height, $pad...) {
  display: inline-block;
  vertical-align: top;
  line-height: $height;
  text-decoration: none !important;
  text-align: center;
  border: none;
  outline: none !important;
  min-height: $height;
  padding: $pad;
  font-size: 19px;
  font-weight: 800;
  color: #1F2024;
  border-radius: 4px;
  background-color: #FFE055;
  transition: all 0.3s;

  @include media-breakpoint-down(sm) {
    font-size: 18px;
  }

  &:hover {
    background-color: #333;
    color: #fff;
  }

  &:disabled, &.disabled {
    cursor: not-allowed;
    background-color: #111 !important;
    color: #fff !important;
  }

  @content;
  
}

// style scrollbar
@mixin scrollbar ($color: #F5F2F7) {

  &::-webkit-scrollbar-track
  {
  border-radius: 5px;
  background-color: transparent;
  }

  &::-webkit-scrollbar
  {
  width: 5px;
  height: 5px;
  background-color: transparent;
  }

  &::-webkit-scrollbar-thumb
  {
  border-radius: 10px;
  background-color: $color;
  }

  @content;
}


// css arrow
@mixin before-arr ($deg,$color,$size) {
  content: '';
  width: #{$size}px;
  height: #{$size}px;
  position: absolute;
  top: 50%;
  margin-top: #{(-$size/2)}px;
  // left: 50%;
  // margin-left: -#{$size};
  border: none;
  border-left: 1px solid $color;
  border-bottom: 1px solid $color;
  transform: rotate(#{$deg}deg);
  transition: all 0.3s;

  @content;
}
