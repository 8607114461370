@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,400i,500,600,700,800,900&display=swap');

@font-face {
    font-family: 'Muller';
    src: url('../fonts/MullerBold.woff2') format('woff2'),
        url('../fonts/MullerBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Muller';
    src: url('../fonts/MullerRegular.woff2') format('woff2'),
        url('../fonts/MullerRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Muller';
    src: url('../fonts/MullerExtraBold.woff2') format('woff2'),
        url('../fonts/MullerExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Muller';
    src: url('../fonts/MullerMedium.woff2') format('woff2'),
        url('../fonts/MullerMedium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}