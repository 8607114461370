input[type=text]:not(.no-style), input[type=password]:not(.no-style), input[type=number]:not(.no-style), input[type=search]:not(.no-style), input[type=email]:not(.no-style) {
	font-family: $font;
	font-size: 17px;
	@include placeholder();
}

textarea {
	font-family: $font;
	font-size: 17px;
	@include placeholder();
}

