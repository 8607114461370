#hero {
	background-color: #F7FAFE;
	overflow: hidden;
	//background: #F7FAFE url(../img/hero.jpg) center top -86px no-repeat;

	@include media-breakpoint-down(lg) {
		background-size: 1400px;
		background-position: center 34px;
	}

	@include media-breakpoint-down(md) {
		background-size: 1200px;
		background-position: center 80px;
	} 

	@include media-breakpoint-down(sm) {
		// background-size: auto;
		// background-position: center -76px;
		// background-image: url(../img/hero-mob-bg.jpg);
	}
}

.rep {
	background: url(../img/rep.png) 0 0 no-repeat;
	width: 375px;
	height: 604px;
	position: absolute;
	right: -115px;
	bottom: -4px;

	@include media-breakpoint-down(lg) {
		background-size: contain;
		width: 300px;
		height: 520px;
		right: -80px;
	}

	@include media-breakpoint-down(md) {
		width: 250px;
		height: 400px;
	}

	@include media-breakpoint-down(sm) {
		bottom: auto;
		top: 200px;
		left: 50%;
		width: 200px;
		margin-left: -0px;
	}
}

.hero-top {
	float: left;
	width: 100%;

	.container {
		position: relative;
	}
}

.hero-top-in {
	float: left;
	width: 100%;
	position: relative;
	padding: 90px 0;

	@media (min-width: 1200px) {
		min-height: 586px;
	}

	@include media-breakpoint-down(md) {
		padding: 60px 0;
	}

	@include media-breakpoint-down(sm) {
		padding: 30px 0 50px;
	}
}

.hero-descr {
	width: 480px;
	float: left;

	@include media-breakpoint-down(lg) {
		width: 400px;
	}

	@include media-breakpoint-down(md) {
		width: 340px;
		position: relative;
		z-index: 1;
	}

	@include media-breakpoint-down(sm) {
		width: 100%;
	}

	h1 {
		font-size: 36px;
		font-weight: 800;
		line-height: 1.05;
		margin: 0 0 20px 0;
		float: left;
		width: 100%;

		@include media-breakpoint-down(lg) {
			font-size: 24px;
		}
	}

	.hero-sub-tit {
		font-size: 20px;
		line-height: 1.2;
		color: #4F5168;
		margin-bottom: 38px;
		float: left;
		width: 100%;
		font-weight: bold;

		@include media-breakpoint-down(lg) {
			font-size: 16px;
		}

		@include media-breakpoint-down(sm) {
			margin-bottom: 365px;
		}
	}

	ul {
		float: left;
		width: 100%;
		list-style: none;
		padding: 0;
		margin: 0 0 25px;

		@include media-breakpoint-down(sm) {
			margin-bottom: 15px;
			position: relative;
			z-index: 1;
		}
		li {
			position: relative;
			font-size: 17px;
			line-height: 1.4;
			margin-bottom: 10px;

			@include media-breakpoint-down(lg) {
				font-size: 15px;
			}

			@include media-breakpoint-down(sm) {
				span {
					font-weight: bold;
					display: block;
				}
			}

			&:before {
				content: '';
				position: absolute;
				left: -19px;
				top: 8px;
				width: 4px;
				height: 4px;
				border-radius: 50%;
				background-color: $defColor;
			}
		}
	}

	.hero-top-info {
		float: left;
		width: 100%;
		margin-bottom: 25px;

		@include media-breakpoint-down(sm) {
			margin-bottom: 10px;
			position: relative;
			z-index: 8;
		}
		.hero-bt {
			float: left;
			margin-right: 25px;

			@include media-breakpoint-down(sm) {
				margin-right: 0;
				width: 100%;
			}

			a {
				width: 290px;

				@include media-breakpoint-down(sm) {
					width: 100%;
				}
			}
		}
	}

	.hero-price {
		width: 160px;
		padding: 12px 0;
		float: left;
		font-size: 18px;
		line-height: 1.2;

		@include media-breakpoint-down(sm) {
			font-size: 16px;
			width: 100%;
			text-align: center;
		}
		span {
			display: block;
			font-weight: bold;

			@include media-breakpoint-down(sm) {
				display: inline;
			}
		}
	}

	.hero-see-det {
		float: left;
		width: 100%;
		a {
			color: $navColor;
			font-size: 18px;
			font-weight: 500;
			position: relative;
			padding-right: 22px;
			text-decoration: underline;

			@include media-breakpoint-down(sm) {
				display: block;
				width: 100%;
				cursor: pointer;
				background-color: #fff;
				border-radius: 5px;
				height: 54px;
				line-height: 54px;
				font-size: 16px;
				text-align: center;
			}

			&:before {
				content: '';
				position: absolute;
				background: url(../img/det-arr.svg) 0 0 no-repeat;
				width: 12px;
				height: 18px;
				right: 0;
				top: 50%;
				margin-top: -12px;

				@include media-breakpoint-down(sm) {
					right: auto;
					left: 50%;
					margin-left: 85px;
				}
			}
			span {}
		}
	}
}

.hero-right {
	position: absolute;
	right: 15px;
	bottom: 0;
	height: 310px;
	width: 100px;

	@include media-breakpoint-down(md) {
		right: -10px;
		padding-top: 106px;
	}

	@include media-breakpoint-down(sm) {
		right: 0;
		width: 100%;
		top: 350px;
	}
}

.made {
	border-radius: 4px;
	float: left;
	width: 100%;
	overflow: hidden;
	margin-bottom: 24px;
	padding: 12px 0 12px 15px;
	font-size: 17px;
	color: #fff;
	line-height: 1.05;
	background: #AAAFD3 url(../img/rus.png) 0 0 no-repeat;

	@include media-breakpoint-down(sm) {
		width: auto;
		float: none;
		display: inline-block;
		font-size: 15px;
		padding: 10px 15px;
	}

	span {
		font-weight: bold;
		display: block;

		@include media-breakpoint-down(sm) {
			display: inline;
		}
	}
}

.gar {
	float: left;
	width: 100%;
	text-align: center;

	@include media-breakpoint-down(sm) {
		position: absolute;
		top: 0;
		left: 50%;
		width: 63px;
		margin-left: 73px;
	}
	&-num {
		font-size: 20px;
		font-weight: 800;
		width: 76px;
		height: 76px;
		line-height: 78px;
		text-align: center;
		display: inline-block;
		background: url(../img/romb.png) 0 0 no-repeat;
		margin-bottom: 7px;

		@include media-breakpoint-down(sm) {
			background-size: contain;
			width: 58px;
			height: 58px;
			line-height: 63px;
			font-size: 17px;
			margin-bottom: 4px;
		}
	}

	&-day {
		font-size: 15px;
		color: #4F5168;

		@include media-breakpoint-down(sm) {
			font-size: 14px;
		}
		span {
			font-weight: bold;
		}
	}
}

.hero-bot {
	float: left;
	width: 100%;
	text-align: center;

	@include media-breakpoint-down(sm) {
		.container {
			padding: 0;
		}
	}

	.hero-video-wrap {
		position: relative;
		z-index: 9;
		float: left;
		width: 100%;
		background-color: #fff;
		padding: 80px 175px 90px;

		@include media-breakpoint-down(lg) {
			padding: 60px 100px;
		}

		@include media-breakpoint-down(md) {
			padding: 30px 30px;
		}
	}

	.hero-vid-tit {
		float: left;
		width: 100%;
		h3 {
			font-size: 27px;
			font-weight: 800;
			margin: 0 0 30px;

			@include media-breakpoint-down(sm) {
				font-size: 20px;
				margin-bottom: 20px;
			}
		}
	}

	.go-you {
		border-radius: 4px;
		float: left;
		width: 100%;
		margin-bottom: 40px;

		@include media-breakpoint-down(sm) {
			margin-bottom: 35px;
		}
		a {
			text-align: left;
			width: 586px;
			height: 51px;
			line-height: 51px;
			padding: 0 75px 0 62px;
			position: relative;
			color: $defColor;
			border-radius: 4px;
			text-decoration: none !important;
			border: 1px solid transparentize(#AAAFD3, 0.5);
			display: inline-block;
			background: url(../img/you-arr.png) right 25px center no-repeat;
			transition: all 0.3s;

			@include media-breakpoint-down(sm) {
				width: 100%;
				background: none;
				position: relative;

				&:after {
					content: '';
					background: url(../img/you-arr.png) right top no-repeat;
					width: 18px;
					height: 12px;
					position: absolute;
					top: 50%;
					margin-top: -6px;
					right: 20px;
				}
			}

			&:hover {
				// border-color: #ff0000;
				color: #ff0000;

				& i {
					svg {
						path {
							fill: #ff0000;
						}
					}
				}
			}

			i {
				content: '';
				position: absolute;
				width: 22px;
				height: 15px;
				left: 25px;
				top: 50%;
				margin-top: -8px;
				vertical-align: top;
				line-height: 1;

				svg {
					path {
						transition: all 0.3s;
					}
				}
			}

			span {
				font-weight: bold;
			}
		}
	}

	
}

.video-frame-img {
	float: left;
	width: 100%;
	img {
		border-radius: 4px;
	}
}

.video-frame {
	float: left;
	width: 100%;
	position: relative;
	text-align: left;
	cursor: pointer;

	@include media-breakpoint-down(sm) {
		width: calc(100% + 60px);
		margin: 0  0 0 -30px;
	}

	.video-tit {
		top: 60px;
		left: 60px;
		position: absolute;
		line-height: 1;
		font-size: 24px;
		font-weight: bold;
		color: $defColor;

		@include media-breakpoint-down(sm) {
			font-size: 16px;
			top: 30px;
			left: 30px;
		}
	}

	.video-tit-one {
		display: block;
		position: relative;
		float: left;
		width: 100%;
		margin-bottom: -10px;
		@include media-breakpoint-down(sm) {
			margin-bottom: -7px;
		}
		span {
			display: inline-block;
			padding: 10px 13px 10px 15px;
			background-color: #fff;

			@include media-breakpoint-down(sm) {
				padding: 7px 10px;
			}
		}
	}

	.video-time {
		position: absolute;
		bottom: 50px;
		left: 60px;
		color: $defColor;
		font-size: 16px;
		font-weight: bold;
		z-index: 9;

		@include media-breakpoint-down(sm) {
			bottom: 24px;
			left: 30px;
			font-size: 14px;
		}
	}
}	

.hide-video {
	display: none;

	@include media-breakpoint-down(sm) {
		iframe {
			height: 300px;
		}
	}
}

#best {
	padding: 80px 0 80px;
	background: linear-gradient(180deg, #000C6A 48.96%, #555EA6 87.5%);

	@include media-breakpoint-down(sm) {
		padding: 40px 0;
	}
}

.best-top-mob {
	display: none;

	@include media-breakpoint-down(sm) {
		display: block;
		color: #fff;

		.era-item-tit {
			display: block !important;
		}

		.era-item-text {
			display: block !important;
		}
	}
}

.era-item {
	float: left;
	width: 100%;
	margin-bottom: 120px;

	@include media-breakpoint-down(lg) {
		margin-bottom: 60px;

		@include media-breakpoint-down(sm) {
			margin-bottom: 45px;
		}
	}

	.era-item-thumb {
		float: left;
		width: 570px;

		@include media-breakpoint-down(lg) {
			width: 50%;
			padding-right: 20px;
		}

		@include media-breakpoint-down(sm) {
			width: 100%;
			padding-right: 0;
			margin-bottom: 30px;
		}

		img {
			border-radius: 4px;
		}
	}

	.era-item-descr {
		float: right;
		width: 570px;
		color: #fff;

		@include media-breakpoint-down(lg) {
			width: 50%;
			padding-left: 30px;
		}

		@include media-breakpoint-down(sm) {
			width: 100%;
			padding-left: 0;
		}
	}

	.era-item-tit {
		float: left;
		font-size: 27px;
		font-weight: 800;
		width: 100%;
		margin-bottom: 37px;

		@include media-breakpoint-down(lg) {
			font-size: 24px;
			margin-bottom: 20px;
		}

		@include media-breakpoint-down(sm) {
			display: none;
		}


	}

	.era-item-text {
		font-size: 17px;
		line-height: 1.5;
		float: left;
		width: 100%;
		margin-bottom: 40px;

		@include media-breakpoint-down(lg) {
			font-size: 15px;
			margin-bottom: 20px;
		}

		@include media-breakpoint-down(sm) {
			display: none;
		}
	}
}

.era-item-can {
	float: left;
	width: 100%;

	@include media-breakpoint-down(sm) {
		width: calc(100% + 30px);
		margin: 0 0 0 -15px;
	}

	.era-item-can-name {
		float: left;
		width: 100%;
		margin-bottom: 23px; 
		span {
			display: inline-block;
			border-radius: 4px;
			font-size: 20px;
			padding: 15px 20px;
			font-weight: bold;
			background-color: rgba(255,255,255,0.2);

			@include media-breakpoint-down(lg) {
				font-size: 16px;
			}

			@include media-breakpoint-down(sm) {
				font-size: 17px;
				border-radius: 0;
				width: 100%;
				display: block;
			}
		}
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0 0 60px;
		float: left;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		font-size: 0;

		@include media-breakpoint-down(lg) {
			margin-bottom: 20px;
		}
		li {
			display: inline-block;
			width: 50%;
			padding: 17px 20px 17px 0;
			position: relative;
			color: #fff;
			font-size: 17px;
			margin-bottom: 10px;
			opacity: 0.9;
			border-bottom: 1px solid rgba(255,255,255,0.15);

			@include media-breakpoint-down(lg) {
				font-size: 15px;
				padding: 12px 20px 12px;
			}

			@include media-breakpoint-down(sm) {
				font-size: 15px;
			}

			&:before {
				content: '';
				position: absolute;
				left: -19px;
				top: 23px;
				width: 4px;
				height: 4px;
				border-radius: 50%;
				background-color: #fff;

				@include media-breakpoint-down(lg) {
					top: 15px;
				}

				@include media-breakpoint-down(sm) {
					left: 10px;
				}
			}
		}
	}
}

.era-item-min {
	float: left;
	width: 100%;
	.one-min-photo {
		float: left;
		margin-right: 33px;

		@include media-breakpoint-down(lg) {
			margin-right: 0;
			width: 50%;
			padding-right: 20px;
		}

		@include media-breakpoint-down(sm) {
			width: 100%;
			padding-right: 0;
			margin-bottom: 20px;
		}

		img {
			border-radius: 4px;

			@include media-breakpoint-down(sm) {
				width: 100%;
			}
		}

		&:last-child {
			margin-right: 0;
		}
	}
}


.item-serv {
	float: left;
	width: 100%;
	text-align: center;
	color: #fff;
	margin-bottom: 50px;

	@include media-breakpoint-down(sm) {
		margin-bottom: 0;
	}
	.item-serv-tit {
		float: left;
		width: 100%;
		font-size: 27px;
		font-weight: bold;
		line-height: 1.2;
		margin-bottom: 33px;

		@include media-breakpoint-down(sm) {
			font-size: 19px;
			margin-bottom: 15px;
		}
	}

	.item-serv-text {
		font-size: 17px;
		line-height: 1.5;
		float: left;
		width: 100%;
		margin-bottom: 50px;
		text-align: center;

		@include media-breakpoint-down(sm) {
			font-size: 15px;
			text-align: left;
		}
		&-in {
			width: 585px;
			padding-bottom: 50px;
			display: inline-block;
			border-bottom: 1px solid rgba(255,255,255,0.2);

			@include media-breakpoint-down(sm) {
				width: 100%;
				padding-bottom: 0px;
				border: none;
				margin-bottom: 30px;
			}
		}
	}
}

.item-serv-wrap {
	float: left;
	width: 100%;
	text-align: center;

	&-in {
		width: 850px;
		display: inline-block;
		font-size: 0;

		@include media-breakpoint-down(md) {
			width: 100%;
		}
	}

	.one-item-serv {
		text-align: left;
		display: inline-block;
		vertical-align: top;
		font-size: 18px;
		width: 25%;

		@include media-breakpoint-down(md) {
			font-size: 16px;
		}

		@include media-breakpoint-down(sm) {
			width: 50%;
			padding-right: 15px;
			margin-bottom: 40px;
		}

		.one-item-serv-thumb {
			float: left;
			width: 100%;
			margin-bottom: 30px;
		}

		.one-item-serv-text {
			line-height: 1.3;
			span {
				display: block;
				font-weight: bold;
			}

		}
	}
}

.item-video {
	float: left;
	width: 100%;
	text-align: center;

	@include media-breakpoint-down(sm) {
		.video-frame {
			width: calc(100% + 30px);
			margin: 0 0 0 -15px;
		}
	}

	&-in {
		width: 850px;
		display: inline-block;

		@include media-breakpoint-down(md) {
			width: 100%;
		}
	}
}

.video-item-info {
	float: left;
	width: 100%;
	text-align: center;
	padding-top: 50px;
	color: #fff;
	
	@include media-breakpoint-down(sm) {
		padding: 30px 15px 0;
		text-align: left;
	}

	&-in {
		display: inline-block;
		font-size: 16px;
		line-height: 1.5;

		span {
			font-weight: bold;
			display: block;
		}
	}
}

#char {
	padding: 80px 0 0;

	@include media-breakpoint-down(sm) {
		padding: 40px 0 0;

		.container {
			padding: 0;
		}
	}
}

.one-body-char {
	float: left;
	width: 100%;
	margin-bottom: 80px;

	@include media-breakpoint-down(sm) {
		margin-bottom: 30px;
	}

	&.rev {
		.char-thumb {
			float: left;

			@include media-breakpoint-down(lg) {
				padding-left: 0;
				padding-right: 20px;
			}

			@include media-breakpoint-down(sm) {
				padding-right: 0;
			}
		}

		.char-descr {
			float: right;

			@include media-breakpoint-down(lg) {
				padding-right: 0;
				padding-left: 20px;
			}

			@include media-breakpoint-down(sm) {
				padding-left: 0;
			}
		}
	}
}

.char-tit {
	font-size: 27px;
	font-weight: 800;
	line-height: 1.2;
	margin-bottom: 40px;
	color: #23293D;

	@include media-breakpoint-down(sm) {
		font-size: 20px;
		margin-bottom: 25px;

		padding: 0 15px;
	}
}

.char-descr {
	float: left;
	width: 570px;

	@include media-breakpoint-down(lg) {
		width: 50%;
		padding-right: 20px;
	}

	@include media-breakpoint-down(sm) {
		width: 100%;
		padding-right: 0;
	}
}

.one-char-tit {
	float: left;
	width: 100%;
	margin-bottom: 25px;
	span {
		font-size: 20px;
		display: inline-block;
		color: #fff;
		border-radius: 4px;
		padding: 15px 20px;
		font-weight: 700;
		background-color: rgba(85,94,166,0.5);

		@include media-breakpoint-down(sm) {
			width: 100%;
			border-radius: 0px;
			padding: 15px 30px;
			font-size: 17px;
		}
	}
}

.one-char-list {
	float: left;
	width: 100%;
	margin-bottom: 40px;
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		font-size: 0;
		display: flex;
		flex-wrap: wrap;
		li {
			padding: 15px 45px 15px 0;
			font-size: 17px;
			line-height: 1.25;
			width: 50%;
			position: relative;
			font-weight: bold;
			display: inline-block;
			vertical-align: top;
			opacity: 0.9;
			border-bottom: 1px solid rgba(0,0,0,0.1);

			@include media-breakpoint-down(sm) {
				padding: 15px 10px 15px 30px;
				word-break: break-word;
				font-size: 15px;
			}

			&:before {
				content: '';
				position: absolute;
				left: -19px;
				top: 23px;
				width: 4px;
				height: 4px;
				border-radius: 50%;
				background-color: #23293D;

				@include media-breakpoint-down(sm) {
					left: 15px;
				}
			}
		}
	}
}

.one-char-body {
	float: left;
	width: 100%;

	@include media-breakpoint-down(sm) {
		padding: 0 30px;
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		li {
			font-size: 0;
			line-height: 1.25;
			float: left;
			width: 100%;
			margin-bottom: 18px;
			position: relative;

			&:before {
				content: '';
				position: absolute;
				background-color: #E1E1E1;
				height: 1px;
				width: 100%;
				top: 9px;
				left: 0;
			}

			span {
				position: relative;
				z-index: 2;
				display: inline-block;
				&.name-char {
					color: #686A7E;
					padding-right: 12px;
					font-size: 16px;
					max-width: calc(50% - 12px);
					white-space: nowrap;

					@include media-breakpoint-down(lg) {
						max-width: calc(50% - 30px);
						white-space: normal;
					}

					@include media-breakpoint-down(sm) {
						max-width: 50%;
						font-size: 15px;
					}

					i {
						font-style: normal;
						background-color: #fff;
						padding-right: 15px;
						position: relative;
						-webkit-box-decoration-break: clone;
						box-decoration-break: clone;


						// &:before {
						// 	content: '';
						// 	position: absolute;
						// 	left: 100%;
						// 	top: 0;
						// 	height: 100%;
						// 	width: 10px;
						// 	background-color: #fff;
						// }
					}
				}

				&.val-char {
					font-size: 17px;
					width: calc(50% + 12px);
					float: right;
					font-weight: 500;
					padding-left: 12px;
					background-color: #fff;

					@include media-breakpoint-down(sm) {
						word-break: break-all;
					}

					@include media-breakpoint-down(sm) {
						width: calc(50% - 17px);
						padding-left: 12px;
						font-size: 16px;
					}
				}
			}
		}
	}
}

.char-thumb {
	float: right;

	@include media-breakpoint-down(lg) {
		padding-left: 20px;
		width: 50%;
	}

	@include media-breakpoint-down(sm) {
		width: 100%;
		padding-left: 0;
		margin-bottom: 30px;
	}

	img {}
}

#instr {
	overflow: hidden;
	margin-top: -40px;

	@include media-breakpoint-down(md) {
		margin-top: 0;
	}
}

.instr-wrap {
	background-color: #F4D757;
	float: left;
	width: 100%;
	margin-top: 40px;

	@include media-breakpoint-down(md) {
		margin-top: 0;
	}
}

.instr-in {
	position: relative;
	padding: 60px 0 0;
	float: left;
	width: 100%;
	height: 379px;

	@include media-breakpoint-down(sm) {
		padding-top: 40px;
		height: 316px;
	}

	.instr-left {
		float: left;
		width: 280px;

		@include media-breakpoint-down(sm) {
			width: 100%;
		}

		.instr-tit {
			float: left;
			width: 100%;
			margin-bottom: 60px;

			@include media-breakpoint-down(sm) {
				width: 50%;
			}

			.one-instr-tit {
				font-size: 24px;
				font-weight: bold;
				display: block;
				position: relative;
				float: left;
				width: 100%;
				margin-bottom: -10px;

				@include media-breakpoint-down(sm) {
					margin-bottom: -7px;
					font-size: 16px;
				}

				span {
					display: inline-block;
					padding: 10px 13px 10px 15px;
					background-color: #fff;

					@include media-breakpoint-down(sm) {
						padding: 7px 10px;
					}
				}
			}
		}

		.inst-info {
			font-size: 18px;
			line-height: 1.2;

			@include media-breakpoint-down(sm) {
				float: right;
				width: 50%;
				font-size: 16px;
			}
			span {
				font-weight: bold;
			}
		}
	}

	.instr-photo {
		position: absolute;
		right: -83px;
		bottom: 0;

		@include media-breakpoint-down(md) {
			width: 65%;
			right: 0;
		}

		@include media-breakpoint-down(sm) {
			width: 100%;
			text-align: center;

			img {
				max-width: 300px;
			}
		}
	}
}

#compl {
	padding: 80px 0 0;

	@include media-breakpoint-down(sm) {
		padding: 40px 0 0;
	}

	.one-body-char {
		overflow: hidden;
		position: relative;

		@include media-breakpoint-down(sm) {
			.container {
				padding: 0px;
			}
		}

		&:before {
			content: '';
			position: absolute;
			left: calc(50% + 30px);
			height: 100%;
			width: 50%;
			background-color: #F2F6FF;
			background: #F2F6FF url(../img/compl.png) 0 center no-repeat;

			@include media-breakpoint-down(lg) {
				background-size: 450px;
			}

			@include media-breakpoint-down(md) {
				background-size: 400px;
			}

			@include media-breakpoint-down(sm) {
				position: static;
				height: 220px;
				float: left;
				width: 100%;
				background-size: 300px;
				background-position: center center;
				margin-bottom: 30px;
			}
		}
	}
}

.compl-info {
	float: left;
	width: 100%;

	@include media-breakpoint-down(sm) {
		padding: 0 30px;
	}
}

.compl-name {
	float: left;
	width: 100%;
	font-weight: bold;
	font-size: 20px;
	line-height: 1.1;
	margin-bottom: 18px;

	@include media-breakpoint-down(sm) {
		margin-bottom: 40px;

		br {
			display: none;
		}
	}
}

.compl-cont {
	float: left;
	width: 100%;
	margin-bottom: 50px;

	@include media-breakpoint-down(sm) {
		margin-bottom: 35px;
		display: flex;
		justify-content: space-between;
	}
}

.compl-mail {
	float: left;
	a {
		text-decoration: none;
		color: $navColor;
		position: relative;
		padding-left: 28px;

		@include media-breakpoint-down(sm) {
			padding-top: 25px;
			padding-left: 0;
		}

		&:before {
			content: '';
			position: absolute;
			background: url(../img/mail.svg) 0 0 no-repeat;
			width: 18px;
			height: 14px;
			left: 0;
			top: 50%;
			margin-top: -8px;

			@include media-breakpoint-down(sm) {
				margin-top: 0;
				top: 0;
			}
		}
	}
}

.compl-phone {
	float: left;
	margin-right: 25px;
	a {
		text-decoration: none;
		color: $navColor;
		position: relative;
		padding-left: 28px;

		@include media-breakpoint-down(sm) {
			padding-left: 0;
			padding-top: 25px;
		}

		&:before {
			content: '';
			position: absolute;
			background: url(../img/phone.svg) 0 0 no-repeat;
			width: 18px;
			height: 18px;
			left: 0;
			top: 50%;
			margin-top: -9px;

			@include media-breakpoint-down(sm) {
				margin-top: 0;
				top: 0;
			}
		}
	}
}

.compl-bt {
	float: left;
	width: 100%;

	@include media-breakpoint-down(sm) {
		padding: 0 30px;
	}
	a {
		width: 290px;

		@include media-breakpoint-down(sm) {
			width: 100%;
		}
	}
}

#trade {
	background-color: #555EA6;
}

.trade {
	color: #fff;
	float: left;
	width: 100%;
	padding: 70px 0 0;
	height: 452px;
	position: relative;

	@include media-breakpoint-down(sm) {
		padding: 40px 0 0;
		height: auto;
		padding-bottom: 220px;
	}
}

.trade-left {
	float: left;
	width: 480px;

	@include media-breakpoint-down(md) {
		width: 50%;
	}

	@include media-breakpoint-down(sm) {
		width: 100%;
	}

	.trade-tit {
		font-size: 27px;
		font-weight: 800;
		margin-bottom: 30px;

		@include media-breakpoint-down(sm) {
			font-size: 19px;
			margin-bottom: 15px;
		}
	}

	.trade-text {
		font-size: 17px;
		line-height: 1.3;
		position: relative;
		padding-bottom: 40px;
		margin-bottom: 50px;

		@include media-breakpoint-down(sm) {
			font-size: 15px;
			margin-bottom: 30px;
			padding-bottom: 0;
			border: none;
		}

		&:before {
			content: '';
			width: 175px;
			height: 1px;
			background-color: rgba(255,255,255,0.2);
			position: absolute;
			left: 0;
			bottom: 0;

			@include media-breakpoint-down(sm) {
				display: none;
			}
		}
	}

	.trade-cont {
		float: left;
		width: 100%;
		.trade-name {
			font-size: 20px;
			font-weight: bold;
			float: left;
			width: 100%;
			margin-bottom: 20px;
		}

		.trade-wrap {
			float: left;
			width: 100%;
			display: flex;
			align-content: center;
			align-items: center;

			@include media-breakpoint-down(sm) {
				display: block;
			}

			.trade-call {
				display: block;
				position: relative;
				float: left;
				width: 202px;
				height: 51px;
				background-color: #fff;
				border-radius: 4px;
				padding-left: 25px;
				text-align: center;
				font-size: 16px;
				font-weight: bold;
				line-height: 51px;
				color: #555EA6;
				text-decoration: none !important;

				@include media-breakpoint-down(sm) {
					margin-bottom: 10px;
				}

				span {
					position: absolute;
					display: block;
					line-height: 1;
					left: 12px;
					top: 50%;
					margin-top: -11px;
				}
			}

			& > span {
				display: block;
				font-size: 16px;
				line-height: 1.1;
				margin-left: 25px;

				@include media-breakpoint-down(sm) {
					margin-left: 0;
					width: 100%;
					display: block;
					float: left;
				}
			}
		}
	}
}

.trade-thumb {
	position: absolute;
	right: 130px;
	bottom: 0;

	@include media-breakpoint-down(lg) {
		right: 0;
	}

	@include media-breakpoint-down(md) {
		width: 50%;
	}

	@include media-breakpoint-down(sm) {
		width: 100%;
		text-align: center;

		img {
			width: 260px;
		}
	}
}

#frames {
	position: absolute !important;
	bottom: -70px;
	left: 495px;

	@include media-breakpoint-down(lg) {
		width: 400px !important;
		height: 540px !important;
		left: 350px;
	}

	@include media-breakpoint-down(md) {
		width: 310px !important;
		height: 422px !important;
		left: 255px;
	}

	@include media-breakpoint-down(sm) {
		width: 240px !important;
		height: 310px !important;
		left: 50%;
		margin-left: -185px;
		bottom: auto;
		top: 200px;

		&:after {
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			height: 50px;
			width: 100%;
			/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+10,f0f5fe+54,f0f5fe+95&0+0,1+100 */
			background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,0.1) 10%, rgba(240,245,254,0.54) 54%, rgba(240,245,254,0.95) 95%, rgba(240,245,254,1) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.1) 10%,rgba(240,245,254,0.54) 54%,rgba(240,245,254,0.95) 95%,rgba(240,245,254,1) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.1) 10%,rgba(240,245,254,0.54) 54%,rgba(240,245,254,0.95) 95%,rgba(240,245,254,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#f0f5fe',GradientType=0 ); /* IE6-9 */

		}
	}
}