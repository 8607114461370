footer {
	float: left;
	width: 100%;
	background-color: #000C6A;
	padding: 70px 0;

	@include media-breakpoint-down(lg) {
		padding: 60px 30px;
	}

	@include media-breakpoint-down(sm) {
		padding: 40px 0 10px;
	}
}

.foot-left {
	float: left;
	width: 600px;
	position: relative;

	@include media-breakpoint-down(lg) {
		width: 100%;
		border-bottom: 1px solid rgba(255,255,255,0.2);
		padding-bottom: 40px;
		margin-bottom: 40px;

		@include media-breakpoint-down(sm) {
			padding-bottom: 30px;
			margin-bottom: 30px;
		}
	}

	&:before {
		content: '';
		position: absolute;
		right: -30px;
		width: 1px;
		height: 100%;
		background-color: rgba(255,255,255,0.2);

		@include media-breakpoint-down(lg) {
			display: none;
		}
	}
}

.logo-soc {
	float: left;
	width: 315px;
	padding-right: 35px;

	@include media-breakpoint-down(lg) {
		width: 50%;
	}

	@include media-breakpoint-down(sm) {
		width: 100%;
		padding-right: 0;
	}
}

.foot-logo {
	float: left;
	width: 100%;
	margin-bottom: 35px;
	a {}
}

.foot-slog {
	font-size: 16px;
	line-height: 1.3;
	color: rgba(255,255,255,0.7);
	float: left;
	width: 100%;
	margin-bottom: 65px;

	@include media-breakpoint-down(sm) {
		margin-bottom: 20px;
	}
}

.foot-soc {
	float: left;
	width: 100%;

	@include media-breakpoint-down(sm) {
		margin-bottom: 35px;
	}
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
		align-content: center;
		align-items: center;
		li {
			float: left;
			margin-right: 20px;
			a {}
		}
	}
}

.foot-phones-mail {
	float: left;
	width: 270px;

	@include media-breakpoint-down(lg) {
		width: 50%;
	}

	@include media-breakpoint-down(sm) {
		width: 100%;
	}
}

.one-pm {
	line-height: 1.3;
	float: left;
	width: 100%;
	margin-bottom: 30px;

	@include media-breakpoint-down(sm) {
		margin-bottom: 15px;
	}

	&:last-child {
		margin-bottom: 0;
	}

	a {
		font-weight: bold;
		font-size: 18px;
		display: block;
		margin-bottom: 10px;
		color: #fff;
		text-decoration: none !important;

		@include media-breakpoint-down(sm) {
			font-size: 16px;
			margin-bottom: 5px;
		}

		&.f-mail {
			font-weight: 500;
		}
	}

	span {
		font-size: 16px;
		color: rgba(255,255,255,0.8);

		@include media-breakpoint-down(sm) {
			font-size: 15px;
		}
	}

	i {
		font-weight: bold;
		font-size: 18px;
		display: block;
		margin-bottom: 10px;
		font-style: normal;
		color: #fff;

		@include media-breakpoint-down(sm) {
			font-size: 16px;
			margin-bottom: 5px;
		}
	}
}

.foot-right {
	float: right;
	width: 570px;
	padding-left: 75px;

	@include media-breakpoint-down(lg) {
		width: 100%;
		padding-left: 0;
	}

	&-in {
		display: flex;
		flex-wrap: wrap;
	}
}

.foot-cont {
	width: 210px;
	font-size: 17px;
	color: #fff;
	line-height: 1.3;
	margin-bottom: 30px;

	@include media-breakpoint-down(lg) {
		width: 50%;
	}

	@include media-breakpoint-down(sm) {
		width: 100%;
		margin-bottom: 15px;
	}
}

.foot-cont-name {
	font-weight: bold;
	margin-bottom: 10px;

	@include media-breakpoint-down(sm) {
		font-size: 16px;
	}
}

.foot-cont-text {
	color: rgba(255,255,255,0.8);

	@include media-breakpoint-down(sm) {
		font-size: 15px;
	}
}