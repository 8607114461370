#bread {
	position: relative;
	z-index: 9;
}

.bread {
	float: left;
	width: 100%;

	@include media-breakpoint-up(lg) {
		display: block;

		ul {
			
			li {
			}
			a {
				
				&:before {
				}
			}
		}
	}
	
}
